exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-customers-tsx": () => import("./../../../src/pages/about/Customers.tsx" /* webpackChunkName: "component---src-pages-about-customers-tsx" */),
  "component---src-pages-about-partners-tsx": () => import("./../../../src/pages/about/partners.tsx" /* webpackChunkName: "component---src-pages-about-partners-tsx" */),
  "component---src-pages-about-team-tsx": () => import("./../../../src/pages/about/team.tsx" /* webpackChunkName: "component---src-pages-about-team-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-idc-press-release-tsx": () => import("./../../../src/pages/IDCPressRelease.tsx" /* webpackChunkName: "component---src-pages-idc-press-release-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledge-tsx": () => import("./../../../src/pages/knowledge.tsx" /* webpackChunkName: "component---src-pages-knowledge-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-resources-contracts-moduleto-meet-contractual-obligations-tsx": () => import("./../../../src/pages/resources/ContractsModuletoMeetContractualObligations.tsx" /* webpackChunkName: "component---src-pages-resources-contracts-moduleto-meet-contractual-obligations-tsx" */),
  "component---src-pages-resources-control-costs-raindrop-spend-management-platform-tsx": () => import("./../../../src/pages/resources/ControlCostsRaindropSpendManagementPlatform.tsx" /* webpackChunkName: "component---src-pages-resources-control-costs-raindrop-spend-management-platform-tsx" */),
  "component---src-pages-resources-corporate-spend-visibility-and-the-complexities-of-managing-spend-tsx": () => import("./../../../src/pages/resources/CorporateSpendVisibilityAndTheComplexitiesOfManagingSpend.tsx" /* webpackChunkName: "component---src-pages-resources-corporate-spend-visibility-and-the-complexities-of-managing-spend-tsx" */),
  "component---src-pages-resources-covi-dhaschangedthewaytheworldgoesouttomarketherehowraindropsourcingmodulegetsyouthere-tsx": () => import("./../../../src/pages/resources/COVIDhaschangedthewaytheworldgoesouttomarketherehowraindropsourcingmodulegetsyouthere.tsx" /* webpackChunkName: "component---src-pages-resources-covi-dhaschangedthewaytheworldgoesouttomarketherehowraindropsourcingmodulegetsyouthere-tsx" */),
  "component---src-pages-resources-digitized-supplier-managementinthe-spotlight-tsx": () => import("./../../../src/pages/resources/DigitizedSupplierManagementintheSpotlight.tsx" /* webpackChunkName: "component---src-pages-resources-digitized-supplier-managementinthe-spotlight-tsx" */),
  "component---src-pages-resources-drive-maximum-efficiencyfromyour-annual-operating-planwith-raindrop-tsx": () => import("./../../../src/pages/resources/DriveMaximumEfficiencyfromyourAnnualOperatingPlanwithRaindrop.tsx" /* webpackChunkName: "component---src-pages-resources-drive-maximum-efficiencyfromyour-annual-operating-planwith-raindrop-tsx" */),
  "component---src-pages-resources-drivevaluewithdigitalprocurementtransformation-tsx": () => import("./../../../src/pages/resources/Drivevaluewithdigitalprocurementtransformation.tsx" /* webpackChunkName: "component---src-pages-resources-drivevaluewithdigitalprocurementtransformation-tsx" */),
  "component---src-pages-resources-embark-on-your-procurement-digitization-journey-with-raindrop-tsx": () => import("./../../../src/pages/resources/EmbarkOnYourProcurementDigitizationJourneyWithRaindrop.tsx" /* webpackChunkName: "component---src-pages-resources-embark-on-your-procurement-digitization-journey-with-raindrop-tsx" */),
  "component---src-pages-resources-evolution-of-contract-management-system-tsx": () => import("./../../../src/pages/resources/EvolutionOfContractManagementSystem.tsx" /* webpackChunkName: "component---src-pages-resources-evolution-of-contract-management-system-tsx" */),
  "component---src-pages-resources-gartner-tsx": () => import("./../../../src/pages/resources/gartner.tsx" /* webpackChunkName: "component---src-pages-resources-gartner-tsx" */),
  "component---src-pages-resources-how-raindrop-planning-module-helps-hedgeagainst-inflation-tsx": () => import("./../../../src/pages/resources/HowRaindropPlanningModuleHelpsHedgeagainstInflation.tsx" /* webpackChunkName: "component---src-pages-resources-how-raindrop-planning-module-helps-hedgeagainst-inflation-tsx" */),
  "component---src-pages-resources-idc-spotlight-raindrop-tsx": () => import("./../../../src/pages/resources/IDCSpotlightRaindrop.tsx" /* webpackChunkName: "component---src-pages-resources-idc-spotlight-raindrop-tsx" */),
  "component---src-pages-resources-intensify-digital-transformationin-bio-techwith-raindrop-tsx": () => import("./../../../src/pages/resources/IntensifyDigitalTransformationinBioTechwithRaindrop.tsx" /* webpackChunkName: "component---src-pages-resources-intensify-digital-transformationin-bio-techwith-raindrop-tsx" */),
  "component---src-pages-resources-procurementand-finance-how-raindrop-harmonizes-the-business-functions-tsx": () => import("./../../../src/pages/resources/ProcurementandFinanceHowRaindropHarmonizesTheBusinessFunctions.tsx" /* webpackChunkName: "component---src-pages-resources-procurementand-finance-how-raindrop-harmonizes-the-business-functions-tsx" */),
  "component---src-pages-resources-procurementand-finance-in-harmonyto-streamline-business-functions-tsx": () => import("./../../../src/pages/resources/ProcurementandFinanceInHarmonytoStreamlineBusinessFunctions .tsx" /* webpackChunkName: "component---src-pages-resources-procurementand-finance-in-harmonyto-streamline-business-functions-tsx" */),
  "component---src-pages-resources-raindrop-addressingtop-prioritiesfor-finance-leaders-tsx": () => import("./../../../src/pages/resources/RaindropAddressingtopPrioritiesforFinanceLeaders.tsx" /* webpackChunkName: "component---src-pages-resources-raindrop-addressingtop-prioritiesfor-finance-leaders-tsx" */),
  "component---src-pages-resources-raindrop-enables-businesses-streamline-their-recovery-tsx": () => import("./../../../src/pages/resources/RaindropEnablesBusinessesStreamlineTheirRecovery.tsx" /* webpackChunkName: "component---src-pages-resources-raindrop-enables-businesses-streamline-their-recovery-tsx" */),
  "component---src-pages-resources-raindrop-spend-intakeand-registration-intuitive-engagingand-powerful-tsx": () => import("./../../../src/pages/resources/RaindropSpendIntakeandRegistrationIntuitiveEngagingandPowerful.tsx" /* webpackChunkName: "component---src-pages-resources-raindrop-spend-intakeand-registration-intuitive-engagingand-powerful-tsx" */),
  "component---src-pages-resources-raindropasa-differentiatorinthe-insurance-industryin-2023-tsx": () => import("./../../../src/pages/resources/RaindropasaDifferentiatorintheInsuranceIndustryin2023.tsx" /* webpackChunkName: "component---src-pages-resources-raindropasa-differentiatorinthe-insurance-industryin-2023-tsx" */),
  "component---src-pages-resources-raindropasthecenterpiecedrivingcostreductionstrategiestohelpnavigateinflation-tsx": () => import("./../../../src/pages/resources/Raindropasthecenterpiecedrivingcostreductionstrategiestohelpnavigateinflation.tsx" /* webpackChunkName: "component---src-pages-resources-raindropasthecenterpiecedrivingcostreductionstrategiestohelpnavigateinflation-tsx" */),
  "component---src-pages-resources-raindrophelpsachieve-critical-digitization-initiativesfor-procurement-leaders-tsx": () => import("./../../../src/pages/resources/RaindrophelpsachieveCriticalDigitizationInitiativesforProcurementLeaders.tsx" /* webpackChunkName: "component---src-pages-resources-raindrophelpsachieve-critical-digitization-initiativesfor-procurement-leaders-tsx" */),
  "component---src-pages-resources-recessionproofyour-e-sourcingstrategy-tsx": () => import("./../../../src/pages/resources/RecessionproofyourESourcingstrategy.tsx" /* webpackChunkName: "component---src-pages-resources-recessionproofyour-e-sourcingstrategy-tsx" */),
  "component---src-pages-resources-shifting-supply-chainsleverage-raindropforresiliencyin-2023-tsx": () => import("./../../../src/pages/resources/ShiftingSupplyChainsleverageRaindropforresiliencyin2023.tsx" /* webpackChunkName: "component---src-pages-resources-shifting-supply-chainsleverage-raindropforresiliencyin-2023-tsx" */),
  "component---src-pages-resources-spend-analyticsasthe-centerpiece-driving-corporate-strategies-tsx": () => import("./../../../src/pages/resources/SpendAnalyticsastheCenterpieceDrivingCorporateStrategies.tsx" /* webpackChunkName: "component---src-pages-resources-spend-analyticsasthe-centerpiece-driving-corporate-strategies-tsx" */),
  "component---src-pages-resources-supplier-diversityand-inclusion-achieve-your-goals-with-raindrop-tsx": () => import("./../../../src/pages/resources/SupplierDiversityandInclusionAchieveYourGoalsWithRaindrop.tsx" /* webpackChunkName: "component---src-pages-resources-supplier-diversityand-inclusion-achieve-your-goals-with-raindrop-tsx" */),
  "component---src-pages-resources-supply-chain-challengesin-2023-navigating-uncertain-times-tsx": () => import("./../../../src/pages/resources/SupplyChainChallengesin2023NavigatingUncertainTimes.tsx" /* webpackChunkName: "component---src-pages-resources-supply-chain-challengesin-2023-navigating-uncertain-times-tsx" */),
  "component---src-pages-resources-the-cfo-roleand-digital-initiativesfor-growth-tsx": () => import("./../../../src/pages/resources/TheCFORoleandDigitalInitiativesforGrowth.tsx" /* webpackChunkName: "component---src-pages-resources-the-cfo-roleand-digital-initiativesfor-growth-tsx" */),
  "component---src-pages-resources-the-digital-platformfor-strategic-sourcing-tsx": () => import("./../../../src/pages/resources/TheDigitalPlatformforStrategicSourcing.tsx" /* webpackChunkName: "component---src-pages-resources-the-digital-platformfor-strategic-sourcing-tsx" */),
  "component---src-pages-resources-the-digital-sourcing-technology-industry-tsx": () => import("./../../../src/pages/resources/TheDigitalSourcingTechnologyIndustry.tsx" /* webpackChunkName: "component---src-pages-resources-the-digital-sourcing-technology-industry-tsx" */),
  "component---src-pages-resources-the-futureof-supply-chainand-procurement-tsx": () => import("./../../../src/pages/resources/TheFutureofSupplyChainandProcurement.tsx" /* webpackChunkName: "component---src-pages-resources-the-futureof-supply-chainand-procurement-tsx" */),
  "component---src-pages-resources-top-5-challengesintheretailindustry-whytechnologytransformationisrequired-tsx": () => import("./../../../src/pages/resources/Top5challengesintheretailindustryWhytechnologytransformationisrequired.tsx" /* webpackChunkName: "component---src-pages-resources-top-5-challengesintheretailindustry-whytechnologytransformationisrequired-tsx" */),
  "component---src-pages-resources-top-5-supply-chainconcernsandhowtomitigatethem-tsx": () => import("./../../../src/pages/resources/Top5SupplyChainconcernsandhowtomitigatethem.tsx" /* webpackChunkName: "component---src-pages-resources-top-5-supply-chainconcernsandhowtomitigatethem-tsx" */),
  "component---src-pages-resources-top-cf-oprioritiesfor-2022-tsx": () => import("./../../../src/pages/resources/TopCFOprioritiesfor2022.tsx" /* webpackChunkName: "component---src-pages-resources-top-cf-oprioritiesfor-2022-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-resources-unleash-the-powerof-advanced-spend-analytics-with-raindrop-tsx": () => import("./../../../src/pages/resources/UnleashThePowerofAdvancedSpendAnalyticsWithRaindrop.tsx" /* webpackChunkName: "component---src-pages-resources-unleash-the-powerof-advanced-spend-analytics-with-raindrop-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-solutions-analytics-tsx": () => import("./../../../src/pages/solutions/Analytics.tsx" /* webpackChunkName: "component---src-pages-solutions-analytics-tsx" */),
  "component---src-pages-solutions-contract-lifecycle-management-tsx": () => import("./../../../src/pages/solutions/ContractLifecycleManagement.tsx" /* webpackChunkName: "component---src-pages-solutions-contract-lifecycle-management-tsx" */),
  "component---src-pages-solutions-payables-tsx": () => import("./../../../src/pages/solutions/Payables.tsx" /* webpackChunkName: "component---src-pages-solutions-payables-tsx" */),
  "component---src-pages-solutions-platform-tsx": () => import("./../../../src/pages/solutions/Platform.tsx" /* webpackChunkName: "component---src-pages-solutions-platform-tsx" */),
  "component---src-pages-solutions-rainsign-tsx": () => import("./../../../src/pages/solutions/Rainsign.tsx" /* webpackChunkName: "component---src-pages-solutions-rainsign-tsx" */),
  "component---src-pages-solutions-sourcing-events-tsx": () => import("./../../../src/pages/solutions/SourcingEvents.tsx" /* webpackChunkName: "component---src-pages-solutions-sourcing-events-tsx" */),
  "component---src-pages-solutions-supplier-management-tsx": () => import("./../../../src/pages/solutions/SupplierManagement.tsx" /* webpackChunkName: "component---src-pages-solutions-supplier-management-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-whyraindrop-tsx": () => import("./../../../src/pages/whyraindrop.tsx" /* webpackChunkName: "component---src-pages-whyraindrop-tsx" */)
}

